import Vue from 'vue'
import constants from '@/const/'
import errors from '@/store/errors'
const vue = Vue.prototype

const actions = {
	getSavedData: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/ws/saved',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addSavedData', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},
	getConfig: function ({ state, commit, getters, rootState }, input) {
		return new Promise((resolve, reject) => {
			rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				query: input,
				url: 'monitoring/ws/config',
				auth: true
			}).then(result => {
				if (result.data) {
					commit('addConfig', result.data)
					resolve(true)
				} else {
				}
			}, () => {
			})
		})
	},

	getFrequencyUpdatesSaved: async function ({ state, commit, getters, rootState }, input) {
		commit('setFrequencyUpdatesSaved')
		try {
			const result = await rootState.request({
				type: constants.METHODS.GET,
				apiName: 'hqsw',
				url: 'bridge/seowork/monitoring/frequency/project/saved',
				auth: true,
				query: input.query
			})

			if (result &&
					result.has_data &&
					result.data &&
					result.data.items &&
					Array.isArray(result.data.items) &&
					result.data.totals
			) {
				commit('setFrequencyUpdatesSaved', result.data.items)
				commit('setFrequencyUpdatesSavedTotals', result.data.totals)
				return true
			}
		} catch (e) {
			vue.$notify({ type: constants.NOTIFY_STATUS.ERROR, title: errors.GET_DATA })
			return false
		}
	}
}

export default actions
