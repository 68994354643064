import Vue from 'vue'

const mutations = {
	addSavedData (state, data) {
		Vue.set(state, 'savedData', data)
	},
	addConfig (state, data) {
		Vue.set(state, 'config', data)
	},
	setFrequencyUpdatesSaved (state, data) {
		if (data && Array.isArray(data)) {
			state.frequencyUpdatesSaved = data
		} else {
			state.frequencyUpdatesSaved = []
		}
	},
	setFrequencyUpdatesSavedTotals (state, data) {
		if (data && Object.keys(data).length) {
			state.frequencyUpdatesSavedTotals = data
		} else {
			state.frequencyUpdatesSavedTotals = {}
		}
	}
}

export default mutations
