const mutations = {
	/* Запись списка разделов в хранилище */
	pushChapters (state, payload) {
		if (payload && payload.data) {
			state.chapters = payload.data
		}
	},

	pushCtrStructure (state, payload) {
		if (payload && payload.data) {
			state.ctrStructure = payload.data
		}
	},

	pushDictionaryValues (state, payload) {
		if (payload && payload.data) {
			state.dictionaryValues = payload.data
		}
	},

	setChapter (state, payload) {
		if (payload && payload.data) {
			state.chapter = payload.data
		}
	}
}
export default mutations
