import AUTH from '@/const/auth.js'
import { Cookies } from '@/function/auth.js'
import { METHODS } from '@/const'
import Vue from 'vue'
import errors from '@/store/errors'

const accessToken = Cookies.get(AUTH.ACCESS_TOKEN)
const vue = Vue.prototype

const actions = {
	getSessions: async function ({ state, commit, getters, rootState }, input) {
		try {
			const result = await rootState.request({
				type: METHODS.GET,
				apiName: 'hqsw',
				url: `bridge/seoapi-old/session/list/organic/${input}`,
				auth: true
			})

			if (result.has_data && result.data) {
				commit('setSessions', result.data)
				return result.data
			}
		} catch (e) {
			vue.$notify({ type: 'error', title: errors.GET_DATA })
			return false
		}
	},

	getSessionsWS: async function ({ state, commit, getters, rootState }) {
		try {
			const response = await fetch('https://seoapi.hqsw.ru/api/session/list/ws/yandex', { Authorizarion: 'Bearer ' + accessToken })
			const sessions = await response.json()
			commit('setSessionsWS', sessions)
		} catch (e) {
			throw new Error(`${e}`)
		}
	}
}

export default actions
